$c-red: #c73b3b;
$c-grey-light: #f8f8f8;
$c-grey-bg: #ebebeb;
$c-grey-dark-text: #636363;

@import "_colors.local.scss";

/*
via CI env - automaticky
 */
$c-red:  #c73b3b ;
